<template>
  <onboarding-layout back-route-name="Home">
    <h1 class="h2 mb-3">
      Met welk huisdier wil je beste maatjes worden?
    </h1>

    <form action="" method="POST" @submit.prevent="onSubmit">
      <Errors :errors="errors" />

      <div class="row types-row">
        <div class="col">
          <input
            class="type-custom-checkbox"
            type="radio"
            name="petType"
            id="petTypeDog"
            value="dog"
            v-model="formType"
            @change="onSubmit"
          >
          <label for="petTypeDog">
            <img
              :src="`/img/themes/${theme.value}/onboarding-dog.svg`"
              alt="Illustratie van een hond"
            >

            <span class="btn btn--primary">
              Puppy
            </span>
          </label>
        </div>

        <div class="col">
          <input
            class="type-custom-checkbox"
            type="radio"
            name="petType"
            id="petTypeCat"
            value="cat"
            v-model="formType"
            @change="onSubmit"
          >
          <label for="petTypeCat">
            <img
              :src="`/img/themes/${theme.value}/onboarding-cat.svg`"
              alt="Illustratie van een kat"
            >

            <span class="btn btn--primary">
              Kitten
            </span>
          </label>
        </div>
      </div>
    </form>
  </onboarding-layout>
</template>

<script>
import OnboardingLayout from './OnboardingLayout.vue';
import Errors from '../../components/Errors.vue';

export default {
  inject: ['theme'],

  components: {
    Errors,
    OnboardingLayout,
  },

  data() {
    return {
      errors: [],
      formType: null,
    };
  },

  created() {
    this.$store.commit('resetLayout', {
      showCat: false,
      showDog: false,
      showCta: false,
      progress: 16.6,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: '1-pet-type',
    });
  },

  methods: {
    onSubmit() {
      this.errors = [];

      if (!this.formType) {
        this.errors.push('Selecteer wat voor huisdier je hebt');
        return;
      }

      this.$store.commit('setPetType', this.formType);

      this.$router.push({ name: 'OnboardingPetName' });
    },
  },
};
</script>

<style>
.types-row {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}

.type-custom-checkbox {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.type-custom-checkbox + label {
  cursor: pointer;
}

.type-custom-checkbox + label:hover img {
  transform: scale(1.1);
}

.type-custom-checkbox + label img[src*='-dog'] {
  transform: scaleX(-1);
}

.type-custom-checkbox + label:hover img[src*='-dog'] {
  transform: scale(-1.1, 1.1);
}

.type-custom-checkbox + label img[src*='-cat'] {
  margin-top: 2px;
}

.type-custom-checkbox + label img {
  display: block;
  margin: 0 auto 2rem;
  transition: transform .2s ease-out;
}

@media (min-width: 640px) {
  .type-custom-checkbox + label img {
    width: 6rem;
  }

  .type-custom-checkbox + label img[src*='-cat'] {
    margin-top: 5px;
  }
}
</style>
